import React, { useContext } from "react";
import background from '../images/background.jpg'

/// React router dom
import {  Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import AllQuran from "./components/AppsMenu/AppProfile/AllQuran";
import VideoClips from "./components/AppsMenu/AppProfile/VideoClips";
import Bayan from "./components/AppsMenu/AppProfile/Bayan";
import kabita from "./components/AppsMenu/AppProfile/kabita";
import Books from "./components/AppsMenu/AppProfile/Books";
import NamazTime from "./components/AppsMenu/AppProfile/NamazTime";
import Compass from "./components/AppsMenu/AppProfile/Compass";
import Gojol from "./components/AppsMenu/AppProfile/Gojol";
import About from "./components/AppsMenu/AppProfile/About";
import ShereApp from "./components/AppsMenu/AppProfile/ShereApp";
import Tasbih from "./components/AppsMenu/AppProfile/Tasbih";
import Pages from "./components/AppsMenu/AppProfile/Pages";
import View_Page from "./components/AppsMenu/AppProfile/View_Page";

import Setting from "./layouts/Setting";


/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";


import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {

  const auth_token = localStorage.getItem('auth_token');
  const { menuToggle } = useContext(ThemeContext);

  const routes = [
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "all-quran", component: AllQuran },
    { url: "video-clips", component: VideoClips },
    { url: "bayan", component: Bayan },
    { url: "kabita", component: kabita },
    { url: "books", component: Books },
    { url: "namaz-time", component: NamazTime },
    { url: "compass", component: Compass },
    { url: "gojol", component: Gojol },
    { url: "about", component: About },
    { url: "shere-app", component: ShereApp },
    { url: "tasbih", component: Tasbih },
    { url: "pages", component: Pages },
    { url: "view-page/:id", component: View_Page },

  ];


  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`} style={{
        backgroundImage: !pagePath
          ? `url(${background})`
          : '',
        backgroundSize: !pagePath ? 'contain' : 'cover',
        backgroundRepeat: !pagePath ? 'repeat' : 'no-repeat',
        backgroundPosition: 'center',
      }}
      >
        {!pagePath && <Nav />}

        <div style={{marginLeft: "0px"}} className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    <ScrollToTop />
    </>
  );
};

export default Markup;
