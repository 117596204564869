import React, { useState, useEffect } from 'react';
import './IslamicCompass.css'; // Stylesheet for compass

const Compass = () => {
  const [direction, setDirection] = useState(0); // Initial direction
  
  useEffect(() => {
    const handleOrientation = (event) => {
      const alpha = event.alpha;
      if (alpha !== null) {
        setDirection(alpha);
      }
    };

    window.addEventListener('deviceorientation', handleOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  return (
    <div className="compass-container">
      <div className="compass">
        <div className="direction north">N</div>
        <div className="direction east">E</div>
        <div className="direction south">S</div>
        <div className="direction west">W</div>
        <div
          className="needle"
          style={{ transform: `rotate(${360 - direction}deg)` }}
        ></div>
      </div>
    </div>
  );
};

export default Compass;
