import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import header_img from "../../../images/arabic-bismillah.png";
import bism from "../../../images/bism.png";

import video_clips_img from "../../../images/video-clips.png";
import bayan_img from "../../../images/bayan.png";
import all_quran_img from "../../../images/all-quran.png";
import books_img from "../../../images/books.png";
import poem_img from "../../../images/poem.png";
import namaz_time_img from "../../../images/namaz-time.png";
import tasbih_img from "../../../images/tasbih.png";
import about_img from "../../../images/about.png";
import shere_app_img from "../../../images/shere-app.png";
import compass from "../../../images/compass.png";
import gojol from "../../../images/gojol.png";
import notification_img from "../../../images/notification.png";
import pages_img from "../../../images/pages.png";


//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import live from '../../../images/profile/live.gif'
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";


const Home = () => {
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "Light", label: "Light" });
    }, []);

   const BaseUrl='https://greenlifeit.live/'

return(
  <>
  <span style={{  marginTop: '-36px' }} className="d-xl-block">
    <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <div className="form-inline">
              <div className="col-xl-12 p-4">
                <div className="row">
  
                  <div className="col-12 int-col d-flex justify-content-center">
                      <div className="justify-content-center">
                        <img  alt="images" width="100%" style={{borderRadius: "100px"}} src={header_img} />                               
                      </div>
                   </div>

                  <div className="col-12 int-col d-flex justify-content-center">
                      <div className="justify-content-center">
                         <p style={{colo: '#686868', textAlign: 'center', fontSize : '20px', margin: '0px', fontWeight: 'bold'}}>BOYAN APP</p>
                         <h5 style={{ textAlign: 'center', color: '#7a7a7a'}}>Empower your spiritual journey with enlightening Islamic boyans on our dedicated app</h5>
                                                        
                      </div>
                   </div>

                  <div className="container">

                    <div className="row d-flex justify-content-center">

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/video-clips">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={video_clips_img} />                      
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Video Clips</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/bayan">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          
                          <img  alt="images" width="32" height="32" src={bayan_img} /><br/>      
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Boyan</h6>                 
                        </div>
                         </Link>
                      </div>
                    
                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/all-quran">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={all_quran_img} /><br/>                    
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>All Quran</h6>
                        </div>
                        </Link>
                      </div>


                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/gojol">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={gojol} /><br/>                     
                          <h6 className="mt-1"  style={{color: '#535353'}}>Gojol</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="#">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={books_img} /><br/>                      
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Books</h6>
                        </div>
                         </Link>
                      </div>
                    

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/kabita">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={poem_img} /><br/>                     
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Kabita</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/namaz-time">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={namaz_time_img} /><br/>                     
                          <h6 className="mt-1"  style={{color: '#535353'}}>Namaz Time</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/compass">
                         <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={compass} /><br/>                     
                          <h6 className="mt-1"  style={{color: '#535353'}}>Compass</h6>
                        </div>
                       </Link>
                      </div>

                    
                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/tasbih">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={tasbih_img} /><br/>                       
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Tasbih</h6>
                        </div>
                       </Link>
                      </div>


                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/about">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={about_img} /><br/>                 
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>About</h6>
                        </div>
                       </Link>
                      </div>
                      
                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                       <Link to="/shere-app">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={shere_app_img} /><br/>                       
                          <h6 className="mt-1"  style={{color: '##4d4d4d'}}>Shere App</h6>
                        </div>
                       </Link>
                      </div>
                    
                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <Link to="/pages">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={pages_img} /><br/>                   
                          <h6 className="mt-1"  style={{color: '#535353'}}>Pages</h6>
                        </div>
                       </Link>
                      </div>

                      <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 col-4 int-col p-1 mb-3 d-flex justify-content-center">
                        <div className=" p-2 rounded" style={{ textAlign: 'center', background: 'white', border: '1px solid gold', width : '110px' }}>
                          <img  alt="images" width="32" height="32" src={notification_img} /><br/>                   
                          <h6 className="mt-1"  style={{color: '#535353'}}>Notification</h6>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Col>
      </Fragment>

      </div>
    </span>
  </>
  )
}
export default Home;