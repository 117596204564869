import React, { useState } from 'react'
import { Link } from 'react-router-dom'

/// Images

import MsgBox from './MsgBox'

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false)
  return (
    <div>

    </div>
  )
}

export default Chat
