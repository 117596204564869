import React,{Fragment,useContext, useEffect,useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useParams, Router, useLocation } from "react-router-dom";
import quranImg from '../../../../images/quran.png'
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion, Modal, Button } from 'react-bootstrap'
import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


   const location = useLocation();
   const BaseUrl  = "https://admin.boyanbd.com/";
   const [homeitem, setHomeItem] = useState([]);   
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
 
 
  const [visibleItems, setVisibleItems] = useState(100); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          search: search,
        };

        const response = await axios.post('/books-filters', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setcount_game(response.data.count_game);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      // Clear data if the query is empty
      setHomeItem([]);
      setLoading(false);
    }

    // Cleanup function to cancel the request if the component unmounts
    return () => source.cancel();
  }, [ search, loading]);

  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 100); // Adjust the number as needed
  };


   const [getway, setGetway] = useState([]);
    const handleLinkClick = (item) => {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          books_id: item.id,
        }
    axios.post('/books-filters-one', data)
      .then(response => {
        setGetway(response.data.data);
        setSelectedItem(response.data);
        setShowModal(true);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setGetway([]);
  };



return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
          
                <div className="mb-2">
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>
                <div>


                </div>
                <div className="form-inline">
                    <div className="row">

                        {homeitem.map((ele) => (
                          <div key={ele.id} className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">
                            <div>
                              <button className="text-black" onClick={() => handleLinkClick(ele)}>
                                <div className="card mb-0">
                                  <div className="img-bx text-center">
                                    <img src={BaseUrl + ele.image} alt="" className="me-3 m-3 card-list-img" width="35%" />
                                    <h5 className="nav-text text-center" style={{ display: "block" }}> {ele.name}</h5>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}


        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{selectedItem && selectedItem.data.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Additional data to display */}
            {selectedItem && (
              <div>
                {selectedItem.data_one.map((ele) => (
                  <div>
                  <div key={ele.id} className="col-12" style={{ textAlign : "center" }}>
                   <img style={{ width : "100%" }} src={BaseUrl + ele.file_location} alt="" className="me-1 m-1 card-list-img" width="35%" />
                  </div>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>



          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;