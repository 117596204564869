import React,{Fragment,useContext, useEffect,useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useParams, Router, useLocation } from "react-router-dom";
import quranImg from '../../../../images/quran.png'
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'

import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


   const location = useLocation();
   const BaseUrl  = "https://admin.boyanbd.com/";
   const [homeitem, setHomeItem] = useState([]); 
   const [homeCasino, setCasino] = useState([]);   
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
 
 
  const [visibleItems, setVisibleItems] = useState(100); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          search: search,
        };

        const response = await axios.post('/quaran-filters', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setcount_game(response.data.count_game);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      // Clear data if the query is empty
      setHomeItem([]);
      setCasino([]);
      setLoading(false);
    }

    // Cleanup function to cancel the request if the component unmounts
    return () => source.cancel();
  }, [ search, loading]);

  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 100); // Adjust the number as needed
  };


  const [activeAudio, setActiveAudio] = useState(null);
  const audioRef = useRef(null);

  const handleClick = (link) => {
    // Check if an audio element is currently playing
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

    // Set the new active audio
    setActiveAudio(link);

    // Play the new audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
    // Add any additional logic you need for handling the click event
  };

  const handleNextAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

    // Calculate the index of the next audio
    const currentIndex = homeitem.findIndex((ele) => ele.link === activeAudio);
    const nextIndex = (currentIndex + 1) % homeitem.length;

    // Set the new active audio
    setActiveAudio(homeitem[nextIndex].link);

    // Play the next audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
  };

  const handlePrevAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

    // Calculate the index of the previous audio
    const currentIndex = homeitem.findIndex((ele) => ele.link === activeAudio);
    const prevIndex = (currentIndex - 1 + homeitem.length) % homeitem.length;

    // Set the new active audio
    setActiveAudio(homeitem[prevIndex].link);

    // Play the previous audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
          
               {activeAudio && (
                <header>
                  <div className="header-nav-wrapper">
                    <div className="fixed-audio-player"  style={{margin: "0px auto", textAlign: "center"}}>
                      <video className="responsive-video" style={{margin: "0px auto", textAlign: "center"}} ref={audioRef} controls>
                        <source src={BaseUrl + activeAudio} type="video/mp4" />
                        Your browser does not support the video element.
                      </video>  
                      <div style={{ float: 'left' }}>
                        <button onClick={handlePrevAudio}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#f6a622" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polygon points="13 19 22 12 13 5 13 19"></polygon>
                            <polygon points="2 19 11 12 2 5 2 19"></polygon>
                          </svg>
                        </button>
                      </div>   
                      <div style={{ float: 'right' }}>
                        <button onClick={handleNextAudio}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#f6a622" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polygon points="11 19 2 12 11 5 11 19"></polygon>
                            <polygon points="22 19 13 12 22 5 22 19"></polygon>
                          </svg>
                        </button>
                      </div>                      
                    </div>
                  </div>
                </header>

                )}

               {activeAudio && (
                <div className="responsive-video-mr"></div>
               )}

                <div className="mb-2">
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>
                <div>


                </div>
                <div className="form-inline">
                    <div className="row">


                   {homeitem.map((ele) => (
                      <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">   
                            <div key={ele.id}>
                              <Link  onClick={() => handleClick(ele.link)} className="text-black">
                                  <div className="card mb-0">
                                  <div style={{paddingTop: "15px",paddingBottom: "15px"}} className="img-bx text-center ">
                                      <h5 className="nav-text text-center" style={{display: "block"}}> {ele.name}</h5>
                                  </div>  
                                  </div>  
                               </Link>
                            </div>  
                        </div>  
                        ))}

                    </div>
                  </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}



          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;