import { Card, Col, ListGroup, Row } from "react-bootstrap";
import React,{useReducer} from "react";
import axios from "axios";
import vs from '../../images/profile/payment.png';
import home from "../../images/svg/home.svg";
import games from "../../images/svg/game.svg";
import profile from "../../images/svg/profile.svg";
import chat from "../../images/svg/chat.svg";
import swal from "sweetalert";
import {Link} from 'react-router-dom';

const Footer = () => {
  var d = new Date();

  return (
    <div  style={{color:"white"}} className="footer m-4">

      <div style={{textAlign:'center'}} className="copyright">
        <footer>
            <div className="footer-nav-wrapper">

                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                        <span>Home</span>
                    </div>
                </Link>


                <Link className="footer-nav-single" to={"/video-clips"}>
                    <div className="menu-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect><line x1="7" y1="2" x2="7" y2="22"></line><line x1="17" y1="2" x2="17" y2="22"></line><line x1="2" y1="12" x2="22" y2="12"></line><line x1="2" y1="7" x2="7" y2="7"></line><line x1="2" y1="17" x2="7" y2="17"></line><line x1="17" y1="17" x2="22" y2="17"></line><line x1="17" y1="7" x2="22" y2="7"></line></svg> 
                        <span>Video Clips</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/bayan"}>
                    <div className="menu-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="4" y="2" width="16" height="20" rx="2" ry="2"></rect><circle cx="12" cy="14" r="4"></circle><line x1="12" y1="6" x2="12.01" y2="6"></line></svg> 
                        <span>Bayan</span>
                    </div>
                </Link>

              <Link className="footer-nav-single" to={"/all-quran"}>
                  <div className="menu-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      <span>All Quran</span>
                  </div>
              </Link>

            </div>
        </footer>






      </div>




    </div>
  );
};

export default Footer;
