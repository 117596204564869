import React, { useState } from 'react';

const RoundDivsAndButtons = () => {
  // Retrieve the initial count from localStorage
  const tasbih = parseInt(localStorage.getItem('tasbih')) || 0;
  const toTasbih = parseInt(localStorage.getItem('to_tasbih')) || 0;

  // Initialize state with the retrieved count
  const [count, setCount] = useState(tasbih);
  const [toTasb, setToTasb] = useState(toTasbih);
  if(localStorage.getItem('tasbih') === null){
    localStorage.setItem('tasbih', 0);
    localStorage.setItem('to_tasbih', 0);
  }
  const handleIncrement = () => {
    // Parse the current count from localStorage and increment it
    let updatedCount = parseInt(localStorage.getItem('tasbih')) + 1;

    // Check if the count reaches 101, then reset to 0 and increment to_tasbih
    if (updatedCount >= 100) {
      updatedCount = 0;
      const updatedToTasbih = toTasb + 1;
      localStorage.setItem('to_tasbih', updatedToTasbih);
      setToTasb(updatedToTasbih);
    }

    // Update localStorage with the new count
    localStorage.setItem('tasbih', updatedCount);

    // Update the state with the new count
    setCount(updatedCount);
  };

  const handleReset = () => {
    // Reset both localStorage and state to 0
    localStorage.setItem('tasbih', 0);
    localStorage.setItem('to_tasbih', 0);
    setCount(0);
    setToTasb(0);
  };
  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-12 text-center rounded p-3">
          <h2>Tasbih Counter</h2> 
              
          <h3>Total Count: {toTasb * 100 + count} 
            <span style={{fontSize: '20px', marginLeft: '10px'}} onClick={handleReset}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#d0021b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2"/></svg>
              </span>
          </h3> 
          <div style={{ background: 'white' }} className="rounded bg-light p-3">
            <h2 style={{ background: 'white', border: '1px solid black' }}> Count: {count} &<span> Loop: {toTasb}  </span></h2>



            <div className="mt-3">
              <button onClick={handleIncrement} style={{borderRadius: '50%'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundDivsAndButtons;
