import React,{Fragment,useContext, useEffect,useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useParams, Router, useLocation } from "react-router-dom";
import quranImg from '../../../../images/quran.png'
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion, Modal, Button } from 'react-bootstrap'

import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


   const location = useLocation();
   const BaseUrl  = "https://admin.boyanbd.com/";
   const [homeitem, setHomeItem] = useState([]); 
   const [homeCasino, setCasino] = useState([]);   
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
   const [homequran, AllQuranSrc] = useState([]); 
 
 
  const [visibleItems, setVisibleItems] = useState(120); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          search: search,
        };

        const response = await axios.post('/quaran-filters', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setcount_game(response.data.count_game);
        setShowModal(false);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      // Clear data if the query is empty
      setHomeItem([]);
      setCasino([]);
      setLoading(false);
    }

    // Cleanup function to cancel the request if the component unmounts
    return () => source.cancel();
  }, [ search, loading]);

  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 120); // Adjust the number as needed
  };


  const [activeAudio, setActiveAudio] = useState(null);
  const audioRef = useRef(null);



    const source = axios.CancelToken.source();
    const fetchData = async (link) => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          search: search,
          link: link,
        };
        const response = await axios.post('/quran-reading', data, {
          cancelToken: source.token,
        });
  
         AllQuranSrc(response.data.data);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };



  const handleClick = (link) => {
    // Check if an audio element is currently playing
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

     fetchData(link);
    // Set the new active audio
    setActiveAudio(link);
    setShowModal(link);

    // Play the new audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
    // Add any additional logic you need for handling the click event
  };

  const handleNextAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

    // Calculate the index of the next audio
    const currentIndex = homeitem.findIndex((ele) => ele.link === activeAudio);
    const nextIndex = (currentIndex + 1) % homeitem.length;

    // Set the new active audio
    setActiveAudio(homeitem[nextIndex].link);
    setShowModal(homeitem[nextIndex].link);

    // Play the next audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
  };

  const handlePrevAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
    }

    // Calculate the index of the previous audio
    const currentIndex = homeitem.findIndex((ele) => ele.link === activeAudio);
    const prevIndex = (currentIndex - 1 + homeitem.length) % homeitem.length;

    // Set the new active audio
    setActiveAudio(homeitem[prevIndex].link);
    setShowModal(homeitem[prevIndex].link);

    // Play the previous audio
    if (audioRef.current) {
      audioRef.current.load(); // Reload the audio to set the new source
      audioRef.current.play(); // Start playing the new audio
    }
  };


  const closeModald = () => {
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };  
return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card  >

                <div className="mb-2">
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>
                <div>

               {activeAudio && (

                        <Modal style={{paddingLeft : "0px"}} show={showModal} onClose={closeModal} >
                          <Modal.Header>
                          <div class="modal-title h4">


                                 <div className="col-md-12 mb-3 justify-content-center align-items-center" style={{ textAlign : 'center' }}>
                                   
                                    <div className="fixed-audio-player">
                                        <button onClick={handlePrevAudio}><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#f6a622" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="13 19 22 12 13 5 13 19"></polygon><polygon points="2 19 11 12 2 5 2 19"></polygon></svg></button>
                                        <button onClick={handleNextAudio}><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#f6a622" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="11 19 2 12 11 5 11 19"></polygon><polygon points="22 19 13 12 22 5 22 19"></polygon></svg></button>
                                   
                                        <audio ref={audioRef} controls>
                                          <source src={BaseUrl + activeAudio} type="audio/mp3" />
                                          Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                  </div>
                          </div>
                            <Button variant="" className="btn-close" onClick={closeModald} type="button"></Button>
                          </Modal.Header>
                          <Modal.Body>
                              <div className="col-md-12 order-md-1">
                                <div className="row">
                                 <div className="col-md-12 mb-3 justify-content-center align-items-center" style={{ textAlign : 'center' }}>
                                    <div>
                                {homequran.map((ele) => (
                                  <div key={ele.id}>
                                      <div className="rooms mt-3 d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="d-flex align-items-center col-6">
                                          <div className="ms-4 bed-text">
                                          <h5>{ele.bangla}</h5>
                                          <h5> {ele.english}</h5>
                                          </div>
                                        </div>
                                        <span className="col-6">
                                            <h4> {ele.arabic} . {ele.ayat}</h4>
                                        </span> 
                                      </div>
                                      <hr />

                                  </div>
                                ))}                                    
                                    </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            
                          <Button  onClick={closeModald}  variant="primary">Close</Button>
                          </Modal.Footer>
                        </Modal>

                )}

                </div>
              <Card.Body className="p-2" >
                <div className="form-inline">
                    <div className="row">
                      {homeitem.slice(0, visibleItems).map((ele) => (
                        <div key={ele.id}>
                          <Link onClick={() => handleClick(ele.link)} className="text-black user-name">
                            <div className="rooms mt-3 d-flex align-items-center justify-content-between flex-wrap">
                              <div className="d-flex align-items-center col-10">
                                <img src={quranImg} alt="" style={{ width: '80px' }} />
                                <div className="ms-4 bed-text">
                                  <h4>{ele.name}</h4>
                                  <div className="users d-flex align-items-center">
                                    <div>
                                      <span className="fs-16 font-w500 me-3">( {ele.id} )</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span className="date bg-secondary col-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                  <circle cx="5.5" cy="17.5" r="2.5"/>
                                  <circle cx="17.5" cy="15.5" r="2.5"/>
                                  <path d="M8 17V5l12-2v12"/>
                                </svg>
                              </span> 
                            </div>
                            <hr />
                          </Link>

                        </div>
                      ))}
                    </div>
                  </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}

              </Card.Body>


            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;