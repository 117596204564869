import React,{Fragment,useContext, useEffect,useState, useRef} from 'react';
import { Link, useParams } from "react-router-dom";
import quranImg from '../../../../images/quran.png'
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'

const YourComponent = () => {

  const { id } = useParams();

 const [seAff, setAff] = useState([]);
    useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            code: id,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
      if (auth_token !== null) {
         axios.post('/view-page',data)
         .then(response=>{
           setAff(response.data.data);
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])




  return (
    <div className="container">



        <Card.Body className="p-2" >
          <div className="form-inline">
              {seAff.map((ele) => (
            <div className="col-md-8 offset-md-2 text-center">
                <h1 className="my-4">{ ele.page_titlel }</h1>
                <p className="lead">{ ele.discription }</p>
            </div>
                
              ))}
          </div>
         </Card.Body>

    </div>
  );
};

export default YourComponent;
