import React, { useState, useRef, useEffect , useReducer} from "react";
import { Modal } from "react-bootstrap";
import { QRCodeCanvas } from "qrcode.react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

//** Import Image */
const BasicDatatable = () => {
  const [rootDomain, setRootDomain] = useState('');
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

/** Link */
const initialState = false;
const reducer = (state, action) =>{
  switch (action.type){
    case 'linkModal':
      return { ...state, link: !state.link }    
    default:
      return state  
  } 
}

  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
   // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

 /*.....income data......*/


//root domain select
  useEffect(() => {
    const currentUrl = window.location.href;
    const rootDomainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/;
    const match = currentUrl.match(rootDomainRegex);

    if (match && match[1]) {
      setRootDomain(match[1]);
    }
  }, []); 
//end root domain select


//** qr code */
  const [url, setUrl] = useState("");
  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };
  const qrCodeEncoder = (e) => {
    setUrl(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={"https://play.google.com/store/apps"}
      size={200}
      bgColor={"#000fff00"}
      level={"H"}
    />
  );

  //shere link message body
  const message_body = "This+is+an+international+Boyan+Apps+and+website.+Install+Link+https://play.google.com/store/apps";

  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [isRCopySuccess, setIsRCopySuccess] = useState(false);


  const linkToCopy = "https://play.google.com/store/apps";
  const handleCopyClick = () => {
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setIsCopySuccess(true);
        setTimeout(() => {
          setIsCopySuccess(false);
        }, 3000); // 3000 milliseconds (3 seconds)
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const handleRCopyClick = () => {
    const linkToCopy = linkToCopy;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setIsRCopySuccess(true);
        setTimeout(() => {
          setIsRCopySuccess(false);
        }, 3000); // 3000 milliseconds (3 seconds)
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };


  const onInit = () => {
    //console.log('lightGallery has been initialized');
  };
    const options = {
      settings: {
      overlayColor: "#000000",
      },
  };
  const [state, dispatch] = useReducer(reducer, initialState);



  return (
    <div className="col-12">


            <div className="col-xl-12">
              <div className="row">

                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      
                  <div className="input-group mb-3 input-primary">
                    <input
                      type="text"
                      className="form-control"
                      value={"https://play.google.com/store/apps"}
                      placeholder="Recipient's username"
                    />
                      <button className="input-group-text" onClick={handleCopyClick}> Copy Link</button>


                  </div>

                      {isCopySuccess && (
                        <p>Copy successful! The link has been copied to the clipboard.</p>
                      )}


                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Shere</h5>
                        </div>
                        <div className="modal-body text-center">

                          <a style={{ fontSize: "32px"}} href={"https://api.whatsapp.com/send/?text="+message_body+"&type=phone_number&app_absent=0"} className="btn-social whatsapp" to="/app-profile"><i className="fab fa-whatsapp" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://pinterest.com/pin/create/button/?url=This+is+an+international+Boyan+Apps+And+website.+you+Install+here.+https://planbet24.com/register?ref=" + linkToCopy + "&type=phone_number&app_absent=0"}  className="btn-social me-1 pinterest" to="/app-profile"> <i className="fab fa-pinterest" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://twitter.com/intent/tweet?url="+message_body+"&type=phone_number&app_absent=0"} className="btn-social me-1 twitter" to="/app-profile"><i className="fab fa-twitter" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://t.me/share/url?url="+message_body+"&type=phone_number&app_absent=0"}  className="btn-social me-1 telegram" to="/app-profile"> <i className="fab fa-telegram" /></a>


                          <a style={{ fontSize: "32px" }} href={"https://www.facebook.com/sharer/sharer.php?u=" + message_body + "&type=phone_number&app_absent=0"}
                            className="btn-social me-1 facebook"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>

                          <a
                            style={{ fontSize: "32px" }}
                            href={`https://plus.google.com/share?url=${message_body}`}
                            className="btn-social me-1 google-plus"
                            to="/app-profile"
                          >
                            <i className="fab fa-google-plus" />
                          </a>

                          <a style={{ fontSize: "32px"}}  href={"https://www.linkedin.com/shareArticle?mini=true&url=" + message_body + "&type=phone_number&app_absent=0"} className="btn-social me-1 linkedin" to="/app-profile"><i className="fab fa-linkedin" /></a>
                          <a
                            style={{ fontSize: "32px" }}
                            href={`https://www.tumblr.com/widgets/share/tool/preview?shareSource=legacy&canonicalUrl=&url=${message_body}&type=phone_number&app_absent=0`}
                            className="btn-social me-1 tumblr"
                            to="/app-profile"
                          >
                            <i className="fab fa-tumblr" />
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                          <div style={{ margin: "0px auto" }} className="qrcode__container">
                            <div className="bg-white p-3" ref={qrRef}>{qrcode}</div>
                            <div className="text-center">
                              <form onSubmit={downloadQRCode}>
                                <button type="submit">
                                  Download 
                                </button>
                              </form>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>




    </div>
  );
};

export default BasicDatatable;
