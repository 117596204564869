import React from 'react';
import {Link} from 'react-router-dom';
import { useParams, Router, useLocation } from "react-router-dom";
import quranImg from '../../../../images/quran.png'
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'

const YourComponent = () => {
  return (
    <div className="container">



        <Card.Body className="p-2" >
          <div className="form-inline">
            <div className="col-md-8 offset-md-2 text-center">
              <h1 className="my-4">Boyan About</h1>
              <p className="lead">Your body text goes here. You can add more paragraphs or customize the content.</p>
              <p className="lead">Your body text goes here. You can add more paragraphs or customize the content.</p>
              <p className="lead">Your body text goes here. You can add more paragraphs or customize the content.</p>
              <p className="lead">Your body text goes here. You can add more paragraphs or customize the content.</p>
              <p className="lead">Your body text goes here. You can add more paragraphs or customize the content.</p>
            </div>
          </div>
         </Card.Body>

    </div>
  );
};

export default YourComponent;
