import React,{Fragment,useContext, useEffect,useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useParams, Router, useLocation } from "react-router-dom";
import quranImg from '../../../../images/namazIn.png'
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'

import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


   const location = useLocation();
   const BaseUrl  = "https://admin.boyanbd.com/";
   const [homeitem, setHomeItem] = useState([]);   
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
 
 
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          search: search,
        };
        const response = await axios.post('/namaz-time', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      setHomeItem([]);
      setLoading(false);
    }
    return () => source.cancel();
  }, [ search, loading]);


return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card  >
              <Card.Body className="p-2" >
                <div className="form-inline">
                    <div className="row">
                      {homeitem.map((ele) => (
                        <div key={ele.id}>
                            <div className="rooms mt-3 d-flex align-items-center justify-content-between flex-wrap">
                              <div className="d-flex align-items-center col-10">
                                <img src={quranImg} alt="" style={{ width: '80px' }} />
                                <div className="ms-4 bed-text">
                                  <h4>{ele.name}</h4>
                                </div>
                              </div>
                              <span className="col-2">
                                <h5>{ele.slug}</h5>
                              </span> 
                            </div>
                            <hr />

                        </div>
                      ))}
                    </div>
                  </div>

              </Card.Body>


            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;